/**
 * Common resources that are loaded & executed by all three entry points:
 * bunker, jobber, AND anchor
 */

// eslint-disable-next-line import/no-internal-modules
import "@jobber/design/dist/foundation.css";
// eslint-disable-next-line import/no-internal-modules
import "@jobber/design/dist/dark.mode.css";
// eslint-disable-next-line import/no-internal-modules
import "@jobber/components/dist/styles.css";
import "@jobber/fonts";
import _ from "lodash";
import reactUJS from "react_ujs";
import { initDatadogLogging } from "~/utilities/errors/Datadog/initDatadogLogging";
import { rollbarInit } from "~/utilities/errors/RollbarSetup";
import { isPciCompliant } from "~/utilities/pciCompliance/isPciCompliant";

export const initSharedResources = (
  sectionContext: __WebpackModuleApi.RequireContext,
) => {
  // IE11's "new Event()" polyfill:
  interface EventParams {
    bubbles: boolean;
    cancelable: boolean;
    detail: object | undefined;
  }

  (function () {
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event: string, params: EventParams) {
      const eventParams = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined,
      };

      const evt = document.createEvent("CustomEvent");

      evt.initCustomEvent(
        event,
        eventParams.bubbles,
        eventParams.cancelable,
        eventParams.detail,
      );

      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.CustomEvent = CustomEvent as any;
  })();
  rollbarInit();
  initDatadogLogging();
  reactUJS.useContext(sectionContext);
  window.isPciCompliant = isPciCompliant;

  _.noConflict();
};
