import {
  ALELO_RANGES,
  CABAL_RANGES,
  CARNET_BINS,
  CARNET_RANGES,
  CARTES_BANCAIRES_RANGES,
  ELO_RANGES,
  HIPERCARD_RANGES,
  JCB_RANGES,
  MADA_RANGES,
  MAESTRO_BINS,
  MAESTRO_RANGES,
  MASTERCARD_RANGES,
  NARANJA_RANGES,
  PANAL_RANGES,
  UNIONPAY_RANGES,
} from "./creditCardConstants";

// Detectors extracted and adapted from activemerchant gem: /gems/activemerchant-1.133.0/lib/active_merchant/billing/credit_card_methods.rb
export const CARD_COMPANY_DETECTORS: {
  [key: string]: (num: string) => boolean;
} = {
  visa: num => /^4\d{12}(\d{3})?(\d{3})?$/.test(num),
  master: num =>
    num?.length === 16 && inBinRange(num.slice(0, 6), MASTERCARD_RANGES),
  elo: num => num?.length === 16 && inBinRange(num.slice(0, 6), ELO_RANGES),
  cabal: num => num?.length === 16 && inBinRange(num.slice(0, 8), CABAL_RANGES),
  alelo: num => num?.length === 16 && inBinRange(num.slice(0, 6), ALELO_RANGES),
  discover: num => /^(6011|65\d{2}|64[4-9]\d)\d{12,15}$/.test(num),
  american_express: num => /^3[47]\d{13}$/.test(num),
  naranja: num =>
    num?.length === 16 && inBinRange(num.slice(0, 6), NARANJA_RANGES),
  diners_club: num => /^3(0[0-5]|[68]\d)\d{11,16}$/.test(num),
  jcb: num => num?.length === 16 && inBinRange(num.slice(0, 4), JCB_RANGES),
  dankort: num => /^5019\d{12}$/.test(num),
  maestro: num =>
    12 <= num?.length &&
    num?.length <= 19 &&
    (inBinRange(num.slice(0, 6), MAESTRO_RANGES) ||
      MAESTRO_BINS.has(num.slice(0, 6))),
  maestro_no_luhn: num => /^(501080|501081|501082)\d{6,13}$/.test(num),
  forbrugsforeningen: num => /^600722\d{10}$/.test(num),
  sodexo: num =>
    /^(606071|603389|606070|606069|606068|600818|505864|505865)\d{10}$/.test(
      num,
    ),
  alia: num => /^(504997|505878|601030|601073|505874)\d{10}$/.test(num),
  vr: num => /^(627416|637036)\d{10}$/.test(num),
  unionpay: num =>
    16 <= num?.length &&
    num?.length <= 19 &&
    inBinRange(num.slice(0, 8), UNIONPAY_RANGES),
  carnet: num =>
    num?.length === 16 &&
    (inBinRange(num.slice(0, 6), CARNET_RANGES) ||
      CARNET_BINS.has(num.slice(0, 6))),
  cartes_bancaires: num =>
    num?.length === 16 && inBinRange(num.slice(0, 6), CARTES_BANCAIRES_RANGES),
  olimpica: num => /^636853\d{10}$/.test(num),
  creditel: num => /^601933\d{10}$/.test(num),
  confiable: num => /^560718\d{10}$/.test(num),
  synchrony: num => /^700600\d{10}$/.test(num),
  routex: num => /^(700676|700678)\d{13}$/.test(num),
  mada: num => num?.length === 16 && inBinRange(num.slice(0, 6), MADA_RANGES),
  bp_plus: num => /^(7050\d\s\d{9}\s\d{3}$|705\d\s\d{8}\s\d{5}$)/.test(num),
  passcard: num => /^628026\d{10}$/.test(num),
  edenred: num => /^637483\d{10}$/.test(num),
  anda: num => /^603199\d{10}$/.test(num),
  "tarjeta-d": num => /^601828\d{10}$/.test(num),
  hipercard: num =>
    num?.length === 16 && inBinRange(num.slice(0, 6), HIPERCARD_RANGES),
  panal: num => num?.length === 16 && inBinRange(num.slice(0, 6), PANAL_RANGES),
};

function inBinRange(number: string, ranges: Array<[number, number]>): boolean {
  const bin = parseInt(number, 10);
  return ranges.some(range => range[0] <= bin && bin <= range[1]);
}
