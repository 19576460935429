/* eslint-disable import/no-internal-modules */
import type {
  RollbarLoggerArgs,
  RollbarPayload,
} from "./ignoreFilters/RollbarPayload";
import { unknownRecaptchaError } from "./ignoreFilters/unknownRecaptchaError";
import { recaptchaLoadRacing } from "./ignoreFilters/recaptchaLoadRacing";
import { recaptchaLoginSession } from "./ignoreFilters/recaptchaLoginSession";
import { resizeObserverError } from "./ignoreFilters/resizeObserverError";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _rollbarConfig: {
      checkIgnore(
        isUncaught: boolean,
        args: RollbarLoggerArgs,
        payload: RollbarPayload,
      ): boolean;
    };
  }
}

export const rollbarInit = () => {
  if (window._rollbarConfig !== undefined) {
    window._rollbarConfig.checkIgnore = function (isUncaught, args, payload) {
      if (unknownRecaptchaError(isUncaught, args, payload) === true) {
        return true;
      }

      if (recaptchaLoadRacing(isUncaught, args, payload) === true) {
        return true;
      }

      if (recaptchaLoginSession(isUncaught, args, payload) === true) {
        return true;
      }

      if (resizeObserverError(isUncaught, args, payload)) {
        return true;
      }

      return false;
    };
  }

  window.dispatchEvent(new CustomEvent("rollbar_config_ready"));
};
