import type { LogsEvent } from "@datadog/browser-logs";

// Ignores sending errors from ResizeObserver to Datadog
const resizeObserverError = (event: LogsEvent) => {
  const errorMessage = event.message;

  return !!errorMessage.includes("ResizeObserver loop");
};

function typeError(event: LogsEvent) {
  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return (
      exceptionDescription?.includes("typeerror") &&
      exceptionDescription?.includes("load failed")
    );
  } catch (e) {
    return false;
  }
}

function abortError(event: LogsEvent) {
  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return (
      exceptionDescription?.includes("aborterror") &&
      (exceptionDescription?.includes("the user aborted a request") ||
        exceptionDescription?.includes("aborted without reason") ||
        exceptionDescription?.includes("fetch is aborted"))
    );
  } catch (e) {
    return false;
  }
}

function chromeExtensionError(event: LogsEvent) {
  const CHROME_EXTENSION_ERROR_STRING = "chrome-extension:";

  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return exceptionDescription?.includes(CHROME_EXTENSION_ERROR_STRING);
  } catch (e) {
    return false;
  }
}

function undefinedScriptError(event: LogsEvent) {
  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();

    return (
      exceptionDescription?.includes("script error") &&
      exceptionDescription?.includes("at undefined @")
    );
  } catch (e) {
    return false;
  }
}

/**
 * Prevent errors caused by tracker blockers that block requests to certain domains but don't block the script itself
 */
function trackerBlockerError(event: LogsEvent) {
  const ERROR_TYPES = [
    "fetch error",
    "xhr error",
    "failed to load",
    "typeerror: load failed",
  ];

  const ERROR_DOMAIN_REGEX = new RegExp(
    /(.*)(fullstory.com|clarity.ms|amplitude.com|ss.getjobber.com|google-analytics.com|split.io)(.*)/i,
  );
  try {
    const exceptionMessage = (
      event.error?.message ??
      event.message ??
      ""
    ).toLowerCase();
    const exceptionURL = event.http?.url ?? "";

    return (
      ERROR_TYPES.some(errorType => exceptionMessage.includes(errorType)) &&
      ERROR_DOMAIN_REGEX.test(exceptionURL)
    );
  } catch {
    return false;
  }
}

export {
  resizeObserverError,
  typeError,
  abortError,
  chromeExtensionError,
  trackerBlockerError,
  undefinedScriptError,
};
