import type { RollbarLoggerArgs, RollbarPayload } from "./RollbarPayload";

// Ignores Rollbar #26201
const recaptchaLoadRacing = (
  isUncaught: boolean | undefined,
  args: RollbarLoggerArgs | undefined,
  payload: RollbarPayload,
) => {
  const request = payload && payload.request;
  if (args && args[0] && typeof args[0] === "string") {
    if (
      args[0].includes(
        "reCAPTCHA error: script not loaded when calling setupRecaptcha",
      ) &&
      (request.url.includes("/login") || request.url.includes("/user_sessions"))
    ) {
      return true;
    }
  }

  return false;
};

export { recaptchaLoadRacing };
