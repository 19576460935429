import type { RollbarLoggerArgs, RollbarPayload } from "./RollbarPayload";

// Ignores Rollbar #32237, #32236, #32235, #32271
const recaptchaLoginSession = (
  isUncaught: boolean | undefined,
  args: RollbarLoggerArgs | undefined,
  payload: RollbarPayload,
) => {
  const request = payload && payload.request;

  if (args && args[0] && typeof args[0] === "string") {
    if (
      args[0].includes("user_sessions:new user_sessions:create") &&
      (request.url.includes("/login") || request.url.includes("/user_sessions"))
    ) {
      return true;
    }
  }

  return false;
};

export { recaptchaLoginSession };
