import type { RollbarLoggerArgs, RollbarPayload } from "./RollbarPayload";

// Ignores Rollbar #32019
const unknownRecaptchaError = (
  isUncaught: boolean | undefined,
  args: RollbarLoggerArgs | undefined,
  payload: RollbarPayload,
) => {
  const body = payload && payload.body;
  const frames = body.trace && body.trace.frames;
  const request = payload && payload.request;

  if (Array.isArray(frames)) {
    const lastFrameItem = frames[frames.length - 1];
    if (
      lastFrameItem.filename.includes("www.gstatic.com/recaptcha/releases/") &&
      (request.url.includes("/login") || request.url.includes("/user_sessions"))
    ) {
      return true;
    }
  }

  return false;
};

export { unknownRecaptchaError };
