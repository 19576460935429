import type { RollbarLoggerArgs, RollbarPayload } from "./RollbarPayload";

// Ignores Rollbar #199 (as part of JOB-76443, the exceptions started )
const resizeObserverError = (
  _isUncaught: boolean | undefined,
  _args: RollbarLoggerArgs | undefined,
  payload: RollbarPayload,
) => {
  const exceptionDescription = payload.body.trace?.exception?.description;

  return !!exceptionDescription?.includes(
    "ResizeObserver loop completed with undelivered notifications.",
  );
};

export { resizeObserverError };
